import React, {useContext, useEffect} from "react";
import SbEditable from 'storyblok-react';
import DynamicComponent from "./dynamicComponent";
import PageContext from "./pageContext";
import {IGeneric} from "../genericInterfaces";
import { PageSettingsStoryblok, PageStoryblok} from "../types";

export interface IPage {
  body?: any[];
  component: "page";
  settings?: PageSettingsStoryblok[];
  title: string | PageStoryblok;
  _uid: string;
}

const Page = ({blok}: IGeneric<IPage>) => {
  const content =
    blok.body?.map(childBlok => <DynamicComponent blok={ childBlok } key={ childBlok._uid }/>);
  const hasTitle = blok.title.length ? (<h1>{ blok.title }</h1>) : null;

  const { hubspotForm } = useContext(PageContext);

  useEffect(() => {
    setTimeout(() => {
      const htmlTag = [...document.children].find((item) => (item.tagName === 'HTML'));
      const elemToObserve = document.getElementsByClassName('cky-modal')?.[0];

      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          let currentClassState = mutation.target.classList.contains('cky-modal-open');
          currentClassState
            ? htmlTag?.classList.add('frozen')
            : htmlTag?.classList.remove('frozen');
        });
      });

      if (elemToObserve) {
        observer.observe(elemToObserve, {attributes: true, childList: true})
      }
    }, 1500);
  }, []);

  const { settings } = blok;
  const pageSettings = settings?.find((a) => a.component === "pageSettings");

  if (pageSettings?.footerHubspotForm?.length && hubspotForm) {
    hubspotForm.current = pageSettings.footerHubspotForm[0]; // eslint-disable-line prefer-destructuring
  }

  // const styleSettings: PageStyleStoryblok[] | undefined = pageSettings?.styles;
  // const stylesValue: PageStyleStoryblok | undefined = styleSettings?.length ? styleSettings[0] : undefined;

  // const styles = {
  //   "--bg-color": stylesValue?.backgroundColor,
  //   "--color": stylesValue?.color,
  //   "--color-headline": stylesValue?.colorHeadline,
  //   "--color-menu-init": stylesValue?.menuText,
  //   "--color-menu": stylesValue?.menuTextHover,
  //   "--color-menu-scroll": stylesValue?.menuTextScrolling,
  //   "--bg-menu": stylesValue?.menuBackground,
  //   "--bg-menu-scroll": stylesValue?.menuBackgroundScrolling
  // };

  // const StylesToString = (styles) => {
  //   Object.keys(styles).forEach(key => {
  //     if (!styles[key]) {
  //       delete styles[key];
  //     }
  //   });
  //   return Object.keys(styles).map(key => `${ key }: ${ styles[key] }`).join(';');
  // }

  return (
    <>
      <SbEditable content={blok}>
        {hasTitle}
        {content}
      </SbEditable>
    </>
  );
};

export default Page;
