/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "@reach/router";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import {Helmet} from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import StoryblokService from "../utils/storyblok-service";
import DynamicComponent from "./dynamicComponent";
import PageContext from "./pageContext";
import { breakPoints } from "../utils/constant";
import {
  ButtonLargeStoryblok,
  FooterLinksStoryblok,
  GlobalStoryblok,
  HeaderSectionStoryblok,
  HubSpotFormStoryblok,
  ImageLinkItemStoryblok,
  LinkItemStoryblok,
  TypographyGeneralStoryblok,
  TypographyLinkStoryblok,
} from "../types";
import "../styles/layout.scss";

interface IHeader {
  component: "commonHeader";
  linksBlock: LinkItemStoryblok[];
  promotionalBlock: TypographyGeneralStoryblok[];
  promotionalBlockMobile: TypographyLinkStoryblok[];
  promotionalLink: TypographyLinkStoryblok[];
  sectionsBlock: HeaderSectionStoryblok[];
  showOn: [];
  show_promotional: boolean;
}

interface IFooter {
  bottomLinks: LinkItemStoryblok[];
  component: "commonFooter";
  copyright: TypographyGeneralStoryblok[];
  emailInputPlaceholder: string;
  loginButton: ButtonLargeStoryblok[];
  mainLinks: FooterLinksStoryblok[];
  socialLinks: ImageLinkItemStoryblok[];
  subscription: HubSpotFormStoryblok[];
  title: TypographyGeneralStoryblok[];
}

type ScreenType = {
  width: number;
  isMobile: boolean;
};

interface LayoutTypes {
  children: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >;
  className: string;
  full_slug: string;
}

const Layout = ({ children, className, full_slug }: LayoutTypes) => {
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [globalData, setGlobalData] = useState<GlobalStoryblok | undefined>(undefined);

  const url = useLocation();
  const splitedUrl = url.pathname.split("/");
  const urlTitle = splitedUrl.slice(-1)[0]
    ? splitedUrl.slice(-1)[0].replace(/[^a-zA-Z0-9 ]/g, " ")
    : splitedUrl.slice(-2)[0].replace(/[^a-zA-Z0-9 ]/g, " ");
  const capitalizedUrlTitle =
    urlTitle.charAt(0).toUpperCase() + urlTitle.slice(1);

  const data = useStaticQuery(graphql`
    query {
      allStoryblokEntry(filter: { full_slug: { eq: "global" } }) {
        nodes {
          content
        }
      }
    }
  `);

  const staticGlobalData = JSON.parse(data.allStoryblokEntry.nodes[0].content);

  const hubspotForm = useRef({});

  const Context = {
    full_slug,
    screen: { width: screenWidth, isMobile } as ScreenType,
    hubspotForm,
  };

  useEffect(() => {
    const getStory = async () => {
      const {
        data: { story },
      } = await StoryblokService.get(
        `cdn/stories/global`
      );
      return story;
    };

    getStory()
      .then(res => setGlobalData(
        res ? res.content : staticGlobalData
      ))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    updateScreenWidth();
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  useEffect(() => {
    setIsMobile(screenWidth <= breakPoints.mobile);
  }, [screenWidth]);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      />
      <Helmet
        script={[
          {
            src: "https://app.storyblok.com/f/storyblok-v2-latest.js",
            type: "text/javascript",
          },
        ]}
      />
      <Helmet>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
          }}
        />
      </Helmet>
      <Helmet title={capitalizedUrlTitle} defer={false} />
      <Helmet>
        <body className={className} />
      </Helmet>
      <HubspotProvider>
        <PageContext.Provider value={Context}>
          {globalData?.header?.map((blok: IHeader, index) => (
            <DynamicComponent blok={blok} key={index} />
          ))}
          <main>{children}</main>
          {globalData?.footer?.map((blok: IFooter, index) => (
            <DynamicComponent blok={blok} key={index} />
          ))}
          <div id="popup-portal" />
        </PageContext.Provider>
      </HubspotProvider>
    </>
  );
};

export default Layout;
